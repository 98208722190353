<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="关键词管理" />
		<TableContainer title="关键词管理">
			<template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">添加分类</el-button>
				<el-button type="success" @click="addTheme1" class="turnBule">关注自动回复</el-button>
				<el-button type="success" @click="addTheme2" class="turnBule">新消息回复</el-button>
				<!-- <el-button type="success" @click="addTheme" class="turnBule">批量删除</el-button> -->
			</template>
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="listorder" label="排序" min-width="160" /> -->
				<!-- <el-table-column prop="type" label="序号" min-width="60" /> -->
				<el-table-column prop="keyword" label="关键词" min-width="130" />
				<el-table-column prop="type" label="回复类型" min-width="100" />
				<el-table-column prop="value" label="回复内容" min-width="100" />
				<el-table-column width="160" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">编辑</el-button>
						<el-button type="text" class="textOrange" @click="textOrange(scope.row)">删除</el-button>
						<!-- <el-button type="text" class="reviewed">不允许修改</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>

				<el-form-item label="关键词" prop="keyword">
					<el-input v-model="themeForm.keyword" maxlength="35" placeholder="请输入分类名称" />
				</el-form-item>

				<el-form-item label="回复类型" prop="type">
					<el-select v-model="themeForm.type" placeholder="请选择链接类型" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="回复内容" prop="value" v-if="diasble == 1">
					<el-input v-model="themeForm.value" type="textarea" maxlength="100" placeholder="请输入描述" />
				</el-form-item>
				<el-form-item label="缩略图" prop="rootPath" v-if="diasble == 0">
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="handleLogo">
						<img v-if="themeForm.rootPath" :src="themeForm.rootPath" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div>
						(最多1张图片,支持PNG、JPEG、GIF格式图片,单张图片不超过10M)
					</div>
				</el-form-item>
			</el-form>
		</GDialog>

		<GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm1('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm1" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="回复类型" prop="type">
					<el-select v-model="themeForm1.type" placeholder="请选择链接类型" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="回复内容" prop="value" v-if="diasble == 1">
					<el-input v-model="themeForm1.value" type="textarea" maxlength="100" placeholder="请输入描述" />
				</el-form-item>
				<el-form-item label="缩略图" prop="rootPath" v-if="diasble == 0">
					<el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="handleLogo">
						<img v-if="themeForm1.rootPath" :src="themeForm.rootPath" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div>
						(最多1张图片,支持PNG、JPEG、GIF格式图片,单张图片不超过10M)
					</div>
				</el-form-item>
			</el-form>
		</GDialog>

	</section>

</template>

<script>
	import {
		weixinKeywordQueryAll,
		weixinKeywordAdd,
		weixinKeywordUpdate,
		weixinKeywordDelete
	} from '@api/wenxinMenu/api_wenxinMenu';
	export default {
		data() {
			return {
				tableData: [],
				role: [],
				diasble: 1,
				options: [{
						id: 0,
						name: '图片'
					},
					{
						id: 1,
						name: '文本'
					}
				],
				total: 0,
				isCheck: false,
				searchForm: {
					page: 1,
					size: 10
				},
				themeDialog: {
					title: '添加分类',
					visible: false
				},
				themeDialog1: {
					title: '新消息回复',
					visible: false
				},
				themeForm: {
					keyword: '',
					type: '',
					fileId: '',
					value: '',
					rootPath: '',
				},
				themeForm1: {
					keyword: '',
					type: '',
					fileId: '',
					value: '',
					rootPath: '',
				},
				rules: {
					catName: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}],
					catIdname: [{
						required: true,
						message: '英文字母,数字或下划线(“_”)',
						trigger: 'blur'
					}, ],
					catRemark: [{
						required: true,
						message: '请输入描述',
						trigger: 'blur'
					}, ],
				},
				defaultProps: {
					children: "children",
					label: "menuName",
				},
				ifMaterialEdit: 0,
			}
		},
		created() {
			this.fetchData()
		},
		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			fetchData() {
				weixinKeywordQueryAll(this.searchForm).then(res => {
					this.tableData = res
					// this.total = res.total
				})
			},
			addTheme() {
				this.themeDialog.visible = true;
				this.themeForm = {};
				this.ifMaterialEdit = 1;

			},
			addTheme1() {
				this.$router.push("/wenxinMenu/messageRich");

			},
			addTheme2() {
				this.themeDialog1.visible = true;
			},
			changeDoctor(val) {
				this.themeForm.type = val;
				if (this.themeForm.type == 0) {
					this.diasble = 0
				} else {
					this.diasble = 1
				}
			},
			submitThemeForm(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					if (this.ifMaterialEdit == 1) {
						weixinKeywordAdd(params).then(res => {
							this.$message.success('新增成功')
							this.resetThemeForm('ref_themeForm')
							this.fetchData()
						})
					} else {
						weixinKeywordUpdate(params).then(res => {
							this.$message.success('修改成功')
							this.resetThemeForm('ref_themeForm')
							this.fetchData()
						})
					}


				})
			},
			submitThemeForm(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm1
					}
					weixinKeywordAdd(params).then(res => {
						this.$message.success('新增成功')
						this.resetThemeForm('ref_themeForm')
						this.fetchData()
					})
				})
			},
			editGarden(row) {
				this.themeDialog.visible = true;
				this.themeForm = row;
				this.ifMaterialEdit = 0;
			},
			resetThemeForm(formName) {
				this.themeDialog.visible = false

			},
			textOrange(row) {
				let params = {
					id: row.id,
				}
				this.$confirm('永久删除' + '这条轮播图数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					weixinKeywordDelete(params).then(res => {
						this.fetchData()
					})
				})
			},
			handleLogo(item) {
				console.log(item)
				//验证图片格式大小信息
				const isJPG =
					item.file.type == 'image/jpeg' ||
					item.file.type == 'image/png' ||
					item.file.type == 'image/gif'
				const isLt2M = item.file.size / 1024 / 1024 < 20
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG 或 PNG或 GIF 格式!')
					return false
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 20M!')
					return false
				}
				//图片格式大小信息没问题 执行上传图片的方法
				if (isJPG && isLt2M == true) {
					//定义FormData对象 存储文件
					let mf = new FormData()
					//将图片文件放入mf
					mf.append('file', item.file)
					mf.append('businessType', 1)
					uploadFile(mf).then((res) => {
						this.themeForm.businessType = res.businessType;
						this.themeForm.fileId = res.id;
						this.themeForm.rootPath = res.rootPath;
						this.themeForm1.fileId = res.id;
						this.themeForm1.rootPath = res.rootPath;
						// if (res.code == 200701) {
						//   this.ruleForm.cover = res.data.path
						// }
					})
				}
			},

		}
	}
</script>

<style>
	/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.el-button--text.reviewed {
		color: #38b9be;
	}

	.reviewed {
		color: #38b9be;
	}

	.el-tree-node__content {
		z-index: 999;
		padding-left: 40px;

	}
</style>
